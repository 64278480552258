import * as React from "react"
import Layout from "../components/layout";
import BackLink from "../components/BackLink";
import {graphql} from "gatsby";
import BlockContent from "../components/BlockContent";

const CvUploadedPage = ({ data }) => {
  const upload = data.sanityUpload;
  return (
    <Layout title="CV was successfully uploaded">
      <section className="max-w-screen-md mx-auto mb-12">
        <BackLink className={"mb-6"} to={'/'}/>
        <h1>{upload.formSuccessTitle}</h1>
        <BlockContent blocks={upload._rawFormSuccessMessage}/>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    sanityUpload {
      formSuccessTitle
      _rawFormSuccessMessage(resolveReferences: {maxDepth: 5})
    }
  }
`

export default CvUploadedPage
